import PageHeader from '../../components/PageHeader';
import BreadCrumb from '../../layout/BreadCrumb';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Steps } from 'antd';
import BasicDetailStep from './steps/BasicDetailStep';
import BusinessDetailStep from './steps/BusinessDetailStep';
import ManagementDetailStep from './steps/ManagementDetailStep';
import ProjectDescriptionStep from './steps/ProjectDescriptionStep';
import ActionStep from './steps/ActionStep';

export default function ViewNewProject() {
	const { newProjectObj } = useSelector((state) => state.obj);

	const [current, setCurrent] = useState(0);

	const next = () => {
		setCurrent(current + 1);
	};

	const prev = () => {
		setCurrent(current - 1);
	};

    const onChange = (value) => {
        setCurrent(value);
      };

	useEffect(() => {}, [newProjectObj]);

	const breadList = [
		{
			title: 'Home',
			href: '/',
		},
		{
			title: 'New Projects',
			href: '/#/pipelines/new-projects',
		},
		{
			title: newProjectObj?.accTradeName?.toUpperCase(),
		},
	];

	const steps = [
		{
			key: 0,
			title: 'Basic Details',
			content: <BasicDetailStep next={next} />,
		},
		{
			key: 1,
			title: 'Business Details',
			content: <BusinessDetailStep next={next} prev={prev} />,
		},
        {
			key: 2,
			title: 'Management Details',
			content: <ManagementDetailStep next={next} prev={prev} />,
		},
        {
			key: 3,
			title: 'Project Description',
			content: <ProjectDescriptionStep next={next} prev={prev} />,
		},
        {
			key: 4,
			title: 'Approval',
			content: <ActionStep prev={prev} />,
		},
	];

	const items = steps.map((item) => ({
		key: item.key,
		title: item.title,
	}));

	return (
		<>
			<div className='w-full flex flex-col'>
				<PageHeader header={'New Projects'} />
				<div className='mt-[.94rem]'>
					<BreadCrumb breadList={breadList} />
				</div>

				<div className='flex items-start mt-[3.19rem] h-full'>
					<div className='new_project_steps_card hidden xl:flex xl:flex-col w-fit xl:w-[29.9375rem]'>
						<span className='step_header_txt'>
							{newProjectObj?.accTradeName?.toUpperCase()}
						</span>
						<span className='step_desc_txt mt-[.58rem]'>
							Reviewing{' '}
							<span className='capitalize'>{newProjectObj?.accTradeName}</span>{' '}
							application
						</span>
						<Steps
							onChange={onChange}
							direction='vertical'
							current={current}
							items={items}
							className='mt-[2.23rem]'
						/>
					</div>

					<div className='new_project_info_card w-fit xl:w-[48.0625rem]'>{steps[current].content}</div>
				</div>
			</div>
		</>
	);
}
