import { Spin, Tag } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { save } from '../../../features/save/saveSlice';
import { useNavigate } from 'react-router-dom';

export default function ActionStep({ prev }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { user } = useSelector((state) => state.auth);
	const { newProjectObj } = useSelector((state) => state.obj);
	const { saving } = useSelector((state) => state.save);

	async function handleApprove() {
		const saveObj = {
			...newProjectObj,
			projId: newProjectObj?.projId,
			projStatus: 'WASH',
			projApprovedBy: user.usrId,
			projApprovedByAcc: user.usrAccId,
			url: '/usr/save_project.action',
		};
		await dispatch(save(saveObj));
		await navigate('/pipelines/new-projects');
	}

	let color =
		newProjectObj?.projStatus === 'DRAFT'
			? '#4BB543'
			: newProjectObj?.projStatus === 'REJECT'
			? '#CE1821'
			: '#808080';

	useEffect(() => {}, [newProjectObj]);

	return (
		<>
			<div className='flex flex-col gap-y-[1.06rem]'>
				<div className='info_desc_card_2'>
					<div className='w-full border-b border-[#F2F4F7] pb-[.75rem]'>
						<span className='action_header_txt'>Project Status</span>
					</div>
					<Tag color={color}>
						{newProjectObj?.projStatus === 'DRAFT'
							? 'APPROVED'
							: newProjectObj?.projStatus?.toUpperCase()}
					</Tag>
				</div>

				<div className='info_desc_card_2'>
					<span className='action_header_txt'>
						Reason for Approval/Rejection
					</span>
					<TextArea
						readOnly
						value={newProjectObj?.projMemo ?? 'N/A'}
						cols={4}
						rows={6}
					/>

					{newProjectObj?.projStatus === 'REJECT' ||
					newProjectObj?.projStatus === 'DRAFT' ? (
						<span>
							The Project was{' '}
							{newProjectObj?.projStatus === 'REJECT' ? 'rejected' : 'approved'}{' '}
							by {newProjectObj?.approverName ?? 'N/A'} From{' '}
							{newProjectObj?.bankName ?? 'N/A'}
						</span>
					) : null}
				</div>
			</div>

			<div className='mt-[8.75rem] flex flex-col'>
				{newProjectObj?.projStatus === 'ACTIVE' ? (
					<button
						disabled={saving}
						onClick={() => handleApprove()}
						className='cstm-btn'>
						{saving ? <Spin /> : 'Send to wash prospect'}
					</button>
				) : null}
			</div>

			<div className='mt-[.75rem] flex flex-col'>
			<button disabled={saving} onClick={() => prev()} className='border-btn'>
					Previous
				</button>
			</div>
		</>
	);
}
