import { Modal } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { uploadFile } from '../../../../features/obj/objSlice';
import { useDispatch } from 'react-redux';
import { save } from '../../../../features/save/saveSlice';
import toast from 'react-hot-toast';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import ViewFileButton from '../../../../components/ViewFileButton';
import { formatMoney } from '../../../../utils';

const ProjectDetail = ({ activeItem, handleFetch, handleRemoveActiveItem }) => {
	const dispatch = useDispatch();

	const [file, setfile] = useState(activeItem?.pjdTaSignature ?? '');

	const confirm = () => {
		Modal.confirm({
			title: 'Confirm Action',
			icon: <ExclamationCircleOutlined />,
			onOk: handleSubmit,
			content: 'Do you confirm to submitting this request?',
			okText: 'Confirm',
			cancelText: 'Cancel',
		});
	};

	const handleSubmit = async () => {
		let saveObj = {
			...activeItem,
			pjdDesc: 'APPROVED',
			url: '/dash/saveProjectDevelopment.action',
		};

		const res = await dispatch(save(saveObj));
		if (res?.payload?.success) {
			await handleFetch();
			await toast.success('Approved successful');
			await handleRemoveActiveItem()
		} else {
			toast.error(
				res?.payload?.messages?.message ??
					'An error occurred while uploading your file'
			);
		}
	};

	const handleFile = async (field, e) => {
		let file = e.target.files[0];
		const formData = new FormData();

		formData.append('file', file);

		const response = await dispatch(uploadFile(formData));

		setfile(response?.payload)

		let saveObj = {
			pjdId: activeItem?.pjdId,
			pjdProjId: activeItem?.pjdProjId,
			[field]: response?.payload,
			url: '/dash/saveProjectDevelopment.action',
		};

		const res = await dispatch(save(saveObj));
		if (res?.payload?.success) {
			await handleFetch();
			await toast.success('File uploaded successfully');
		} else {
			toast.error(
				res?.payload?.messages?.message ??
					'An error occurred while uploading your file'
			);
		}
	};	

	useEffect(() => {}, [activeItem]);

	return (
		<div className='w-full h-[170vh] '>
			<div className='flex items-center h-[6.125rem] p-[2.31rem] border border-[#F4F4F4]'>
				<div className='text-[1.125rem] font-[500] '>
					Payment Request - {activeItem?.accTradeName}
				</div>
			</div>

			<div className='flex flex-col p-[1.81rem] border border-[#F4F4F4]'>
				<div className='text-[0.875rem] text-blk font-[700]'>
					Date of request
				</div>
				<div className='text-[0.875rem] text-blk mt-[0.38rem] font-[400]'>
					{moment(activeItem?.pjdCreatedDate).format('LL')}
				</div>
				<div className='mt-[1.88rem] text-blk font-[700]'>SSWP Details</div>
				<div className='mt-[1.12rem] text-blk font-[500]'>
					Name of small scale water provider
				</div>
				<div className='text-blk'>{activeItem?.accTradeName}</div>
				<div className='mt-[1.12rem] text-blk font-[500]'>
					Name of contact person
				</div>
				<div className='text-blk'>{activeItem?.usrFullNames}</div>
				<div className='mt-[1.12rem] text-blk font-[500]'>
					Phone number - Contact person
				</div>
				<div className='text-blk'>{activeItem?.usrMobileNumber}</div>
				<div className='mt-[1.88rem] text-blk font-[700]'>Payment details</div>

				<div className='flex flex-row'>
				<div className='flex flex-col  w-[50%]'>
								<div className='mt-[1.12rem] text-blk font-[500]'>
									Name of supplier
								</div>
								<div className='text-blk'>{activeItem?.pjdSupplierName}</div>
								<div className='mt-[1.12rem] text-blk font-[500]'>
									Contract Number
								</div>
								<div className='text-blk'>{activeItem?.pjdContractNumber}</div>
								<div className='mt-[1.12rem] text-blk font-[500]'>
									Contract Sum
								</div>
								<div className='text-blk'>{formatMoney(activeItem?.pjdContractSum)}</div>
								<div className='mt-[1.12rem] text-blk font-[500]'>
									Paid to date
								</div>
								<div className='text-blk'>{formatMoney(activeItem?.pjdPaidAmount)}</div>
								{/* <div className='mt-[1.12rem] text-blk font-[500]'>
									Value of the certificate
								</div> */}
								{/* <div className='text-blk'>{activeItem?.}</div> */}
								<div className='mt-[1.12rem] text-blk font-[500]'>
									Balance remaining
								</div>
								<div className='text-blk'>
									{formatMoney(activeItem?.pjdBalanceRemaining)}
								</div>
							</div>
					<div className='flex flex-col  w-[50%]'>
						<div className='mt-[1.12rem] text-blk font-[500]'>Bank name</div>
						<div className='text-blk'>{activeItem?.pjdBankName}</div>
						<div className='mt-[1.12rem] text-blk font-[500]'>Branch</div>
						<div className='text-blk'>{activeItem?.pjdBranch}</div>
						<div className='mt-[1.12rem] text-blk font-[500]'>SWIFT Code</div>
						<div className='text-blk'>{activeItem?.pjdSwiftCode}</div>
						<div className='mt-[1.12rem] text-blk font-[500]'>Account name</div>
						<div className='text-blk'>{activeItem?.pjdAccountName}</div>
						<div className='mt-[1.12rem] text-blk font-[500]'>
							Account number
						</div>
						<div className='text-blk'>{activeItem?.pjdAccountNumber}</div>
					</div>
				</div>
				<div className='mt-10 flex flex-row justify-between mr-10 w-full'>
					<div className='flex flex-col w-[50%]'>
						{/* <span className='text-[0.875rem] font-[700] text-blk'>
							Booster Water signature
						</span>
						<img
							className='mt-[.88rem] w-[10.25rem] h-[3.3125rem] object-contain'
							src={
								activeItem?.pjdWspSignature ??
								'https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Placeholder_view_vector.svg/991px-Placeholder_view_vector.svg.png'
							}
							alt='WSP Signature'
						/> */}
					</div>

					<div className='flex flex-col w-[50%]'>
						<span className='text-[0.875rem] font-[700] text-blk'>
							Technical advisor signature
						</span>

						<div className='mt-[.88rem] w-[10.25rem] h-[3.3125rem]'>
							<label
								className='cursor-pointer flex items-center'
								htmlFor='pjdTaSignature'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='15'
									height='19'
									viewBox='0 0 15 19'
									fill='none'>
									<path
										d='M13.6467 6.82752L8.61312 15.4155C7.6458 17.0659 5.50447 17.6313 3.82805 16.679C2.15162 15.7267 1.57728 13.6186 2.54461 11.9683L8.01593 2.63349C8.61996 1.60293 9.95979 1.24915 11.0066 1.8438C12.0534 2.43845 12.4128 3.75746 11.8088 4.78802L7.21284 12.6292C6.97211 13.04 6.43378 13.1821 6.01657 12.9451C5.59936 12.7081 5.45498 12.1781 5.69572 11.7674L9.85392 4.67299L8.71607 4.02663L4.55787 11.1211C3.95384 12.1516 4.3132 13.4706 5.36002 14.0653C6.40683 14.6599 7.74666 14.3061 8.35069 13.2756L12.9466 5.43438C13.9139 3.78399 13.3396 1.67593 11.6632 0.723627C9.98674 -0.228677 7.84541 0.336739 6.87808 1.98713L1.40677 11.3219C0.0761424 13.5921 0.865455 16.4892 3.17149 17.7992C5.47752 19.1091 8.42035 18.3321 9.75097 16.0619L14.7846 7.47388L13.6467 6.82752Z'
										fill='#0170BD'
									/>
								</svg>
								<input
									accept='.png,.jpg,.jpeg'
									id='pjdTaSignature'
									type='file'
									onChange={(e) => handleFile('pjdTaSignature', e)}
									hidden
								/>
								<span className='attach_file_text !text-[#0170BD] ml-[.3rem]'>
									{file
										? file?.split('_')?.pop()
										: 'Attach file'}
								</span>
							</label>
						</div>
					</div>
				</div>

				<div className='mt-[4.06rem]'>
					{!activeItem?.pjdDesc && activeItem?.pjdStatus !== 'PAID' ? (
						<div className='flex w-full justify-end -ml-[3rem]'>
							<button onClick={confirm} type='button' className='cstm-btn-2'>
								Approve
							</button>
						</div>
					) : (
						<>
							<div className='flex items-center w-full h-[6.125rem] p-[2.31rem] border border-[#F4F4F4]'>
								<div className='text-[1.125rem] font-[500] '>
									Proof of Payment - {activeItem?.accTradeName}
								</div>
							</div>
							<div className='flex items-center justify-between w-full h-[7.5625rem] p-[3.12rem] border border-[#F4F4F4]'>
								<div className='text-[1.125rem] text-[#0170BD] font-[500] w-full'>
									<ViewFileButton file={activeItem?.pjdPaymentProof} />
								</div>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default ProjectDetail;
