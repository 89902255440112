import React, { useEffect, useState } from 'react';
import ProjectDetail from './components/ProjectDetail';
import PageHeader from '../../../components/PageHeader';
import BreadCrumb from '../../../layout/BreadCrumb';
import Search from '../../../components/Search';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProjectDevelopments } from '../../../features/fetch/fetchSlice';
import SingleProject from './components/SingleProject';

const breadList = [
	{
		title: 'Home',
		href: '/',
	},
	{
		title: 'Projects',
		href: '/',
	},
	{
		title: 'Project Development',
	},
];

const ProjectDevelopment = () => {
	const dispatch = useDispatch();

	const { projectDevelopment } = useSelector((state) => state.fetch);
	const { user } = useSelector((state) => state.auth);

	const [value, setvalue] = useState('');
	const [activeItem, setactiveItem] = useState({});

	const arrayCopy = !projectDevelopment?.length ? [] : [...projectDevelopment]?.filter(
		(item) =>
			String(item?.accTradeName).toUpperCase().includes(String(value).toUpperCase()) ||
			String(item?.accCounty)
				.toUpperCase()
				.includes(String(value).toUpperCase())
	);

	function handleVActiveProjectChange(obj) {
		setactiveItem(obj);
	}

	function handleRemoveActiveItem() {
		setactiveItem({});
	}

	function handleValueChange(val) {
		setvalue(val);
	}

	async function handleFetch() {
		await dispatch(fetchProjectDevelopments());
	}

	useEffect(() => {
		if (projectDevelopment?.length && !activeItem?.pjdId) {
			handleVActiveProjectChange(projectDevelopment[0]);
		}
	}, [projectDevelopment, value, activeItem]);

	useEffect(() => {}, [value, activeItem]);

	useEffect(() => {
		handleFetch();
	}, []);

	return (
		<div className='flex flex-col min-h-screen  w-full'>
			<PageHeader header={'Project Development'} />
			<div className='mt-[.94rem]'>
				<BreadCrumb breadList={breadList} />
			</div>
			<div className='mt-[2.19rem] w-[40%]'>
				<Search handleValueChange={handleValueChange} />
			</div>

			<div className='flex flex-row bg-white mt-[3rem] w-full h-[650px]'>
			{projectDevelopment?.length ? (
					<>
						<div className='flex w-[40%] p-[1.63rem] h-full overflow-y-auto flex-col'>
							{arrayCopy?.map((item) => {
								return (
									<>
										<SingleProject
											activeId={activeItem?.pjdId}
											handleVActiveProjectChange={handleVActiveProjectChange}
											item={item}
											bgColor={'#E6EAEF'}
										/>
									</>
								);
							})}
						</div>
						<div className='flex w-[60%] h-full overflow-y-auto flex-col pr-[1.63rem] py-[1.63rem]'>
							{Object.keys(activeItem)?.length ? (
								<ProjectDetail
									handleFetch={handleFetch}
									handleRemoveActiveItem={handleRemoveActiveItem}
									activeItem={activeItem}
								/>
							) : null}
						</div>
					</>
				) : (
					<div className='w-full p-[2.31rem] h-[15rem] flex justify-center items-center'>
						<span className='upload_txt'>No request has been submited</span>
					</div>
				)}
			</div>
		</div>
	);
};

export default ProjectDevelopment;
